import { IThunderboltEntry } from '@wix/editor-elements-types';

const entry: IThunderboltEntry = {
  loadComponent: hostAPI => {
    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryDefaultSkin/MatrixGalleryDefaultSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryDefaultSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryDefaultSkin',
    );

    hostAPI.registerComponent(
      'MatrixGallery',
      () => {
        return import(
          './viewer/skinComps/MatrixGalleryTextSlideUpSkin/MatrixGalleryTextSlideUpSkin.skin' /* webpackChunkName: "MatrixGallery_MatrixGalleryTextSlideUpSkin" */
        ).then(componentModule => {
          return {
            component: componentModule.default,
          };
        });
      },
      'MatrixGalleryTextSlideUpSkin',
    );
  },
};

export default entry;
